<template>
  <div ref="root" class="Observer" />
</template>

<script setup lang="ts">
const props = defineProps({
  rootMargin: {
    type: String,
    default: '100px 0px 0px',
  },
});

const observer = ref<IntersectionObserver | null>(null);
const emit = defineEmits(['intersect']);
const root = ref<Element | null>(null);
onMounted(() => {
  observer.value = new IntersectionObserver(
    ([entry]) => {
      if (entry && entry.isIntersecting) {
        emit('intersect');
      }
    },
    {
      rootMargin: props.rootMargin,
    },
  );
  if (root.value) observer.value.observe(root.value);
});

onUnmounted(() => {
  if (observer.value) observer.value.disconnect();
});
</script>

<style lang="postcss" scoped>
.Observer {
  @apply absolute bottom-0;
}
</style>
