<template>
  <div class="LoadingDots flex justify-center gap-x-4">
    <span class="LoadingDot"></span>
    <span class="LoadingDot"></span>
    <span class="LoadingDot"></span>
  </div>
</template>

<style lang="postcss" scoped>
.LoadingDot {
  width: 8px;
  height: 8px;
  margin-inline: 2px;

  &::before {
    @apply bg-primary-1-100;
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    animation: dotPulse 1.5s infinite linear;
  }

  &:nth-child(2)::before {
    animation-delay: 0.25s;
  }

  &:nth-child(3)::before {
    animation-delay: 0.5s;
  }
}

@keyframes dotPulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  30% {
    transform: scale(0);
    opacity: 0;
  }
  60%,
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
